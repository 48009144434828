// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from 'D:/WEB_XGJ_PRO/xinguanjia_web_antdpro/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/PageLoading/index';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BlankLayout' */'D:/WEB_XGJ_PRO/xinguanjia_web_antdpro/src/layouts/BlankLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/other",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__Other' */'D:/WEB_XGJ_PRO/xinguanjia_web_antdpro/src/layouts/Other'), loading: LoadingComponent}),
        "routes": [
          {
            "name": "video",
            "path": "/other/video",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Video' */'D:/WEB_XGJ_PRO/xinguanjia_web_antdpro/src/pages/Video'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "comprehensive-analysis",
            "path": "/other/comprehensive-analysis",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ComprehensiveAnalysis' */'D:/WEB_XGJ_PRO/xinguanjia_web_antdpro/src/pages/ComprehensiveAnalysis'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "operation-video",
            "path": "/other/operation-video",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationVideo' */'D:/WEB_XGJ_PRO/xinguanjia_web_antdpro/src/pages/OperationVideo'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/open-window",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__OpenWindow' */'D:/WEB_XGJ_PRO/xinguanjia_web_antdpro/src/layouts/OpenWindow'), loading: LoadingComponent}),
        "routes": [
          {
            "path": "/",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__Middleware' */'D:/WEB_XGJ_PRO/xinguanjia_web_antdpro/src/layouts/Middleware'), loading: LoadingComponent}),
            "routes": [
              {
                "path": "/open-window/business",
                "name": "business",
                "icon": "smile",
                "routes": [
                  {
                    "path": "/open-window/business/ecg",
                    "name": "ecg",
                    "icon": "smile",
                    "routes": [
                      {
                        "name": "ecg-reports-edit",
                        "path": "/open-window/business/ecg/ecg-reports-edit",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OpenWindow__Business__Ecg__EcgReportsEdit' */'D:/WEB_XGJ_PRO/xinguanjia_web_antdpro/src/pages/OpenWindow/Business/Ecg/EcgReportsEdit'), loading: LoadingComponent}),
                        "bussinessNo": 2000025,
                        "exact": true
                      },
                      {
                        "name": "screen-detail",
                        "path": "/open-window/business/ecg/screen-detail",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OpenWindow__Business__Ecg__ScreenDetail' */'D:/WEB_XGJ_PRO/xinguanjia_web_antdpro/src/pages/OpenWindow/Business/Ecg/ScreenDetail'), loading: LoadingComponent}),
                        "bussinessNo": 2000041,
                        "exact": true
                      },
                      {
                        "name": "confirm-event",
                        "path": "/open-window/business/ecg/confirm-event",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OpenWindow__Business__Ecg__ConfirmEvent' */'D:/WEB_XGJ_PRO/xinguanjia_web_antdpro/src/pages/OpenWindow/Business/Ecg/ConfirmEvent'), loading: LoadingComponent}),
                        "bussinessNo": 2000025,
                        "exact": true
                      },
                      {
                        "name": "screen-follow-up",
                        "path": "/open-window/business/ecg/screen-follow-up",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OpenWindow__Business__Ecg__ScreenFollowUpDetail' */'D:/WEB_XGJ_PRO/xinguanjia_web_antdpro/src/pages/OpenWindow/Business/Ecg/ScreenFollowUpDetail'), loading: LoadingComponent}),
                        "bussinessNo": 2000025,
                        "exact": true
                      },
                      {
                        "name": "ecg-reports-wholePreview",
                        "path": "/open-window/business/ecg/ecg-reports-edit/whole-preview",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OpenWindow__Business__Ecg__EcgReportsEdit__components__EcgWholePreview' */'D:/WEB_XGJ_PRO/xinguanjia_web_antdpro/src/pages/OpenWindow/Business/Ecg/EcgReportsEdit/components/EcgWholePreview'), loading: LoadingComponent}),
                        "exact": true
                      },
                      {
                        "name": "ecg-reports-printView",
                        "path": "/open-window/business/ecg/ecg-reports-edit/whole-preview/print-view",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OpenWindow__Business__Ecg__EcgReportsEdit__components__EcgWholePreview__PrintView' */'D:/WEB_XGJ_PRO/xinguanjia_web_antdpro/src/pages/OpenWindow/Business/Ecg/EcgReportsEdit/components/EcgWholePreview/PrintView'), loading: LoadingComponent}),
                        "exact": true
                      },
                      {
                        "name": "ekg",
                        "path": "/open-window/business/ecg/rhc-ecg-detail",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OpenWindow__Business__Ecg__EcgReportsEdit' */'D:/WEB_XGJ_PRO/xinguanjia_web_antdpro/src/pages/OpenWindow/Business/Ecg/EcgReportsEdit'), loading: LoadingComponent}),
                        "bussinessNo": 2000025,
                        "exact": true
                      },
                      {
                        "name": "ecg-detail",
                        "path": "/open-window/business/ecg/ecg-detail",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OpenWindow__Business__Ecg__EcgDetail' */'D:/WEB_XGJ_PRO/xinguanjia_web_antdpro/src/pages/OpenWindow/Business/Ecg/EcgDetail'), loading: LoadingComponent}),
                        "bussinessNo": 0,
                        "exact": true
                      },
                      {
                        "name": "editing-template",
                        "path": "/open-window/business/ecg/editing-template",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OpenWindow__Business__Ecg__EditingTemplate' */'D:/WEB_XGJ_PRO/xinguanjia_web_antdpro/src/pages/OpenWindow/Business/Ecg/EditingTemplate'), loading: LoadingComponent}),
                        "bussinessNo": 0,
                        "exact": true
                      },
                      {
                        "name": "redux-demo",
                        "path": "/open-window/business/ecg/redux-demo",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OpenWindow__Business__Ecg__ReduxDemo' */'D:/WEB_XGJ_PRO/xinguanjia_web_antdpro/src/pages/OpenWindow/Business/Ecg/ReduxDemo'), loading: LoadingComponent}),
                        "bussinessNo": 0,
                        "exact": true
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "path": "/",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__SecurityLayout' */'D:/WEB_XGJ_PRO/xinguanjia_web_antdpro/src/layouts/SecurityLayout'), loading: LoadingComponent}),
        "routes": [
          {
            "path": "/",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BasicLayout' */'D:/WEB_XGJ_PRO/xinguanjia_web_antdpro/src/layouts/BasicLayout'), loading: LoadingComponent}),
            "authority": [
              "admin",
              "user"
            ],
            "routes": [
              {
                "path": "/",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__Middleware' */'D:/WEB_XGJ_PRO/xinguanjia_web_antdpro/src/layouts/Middleware'), loading: LoadingComponent}),
                "routes": [
                  {
                    "path": "/",
                    "redirect": "/business/ecg/ecg-reports",
                    "exact": true
                  },
                  {
                    "path": "/admin**",
                    "name": "admin",
                    "icon": "FundViewOutlined",
                    "id": 345,
                    "routes": [
                      {
                        "path": "/admin**/business",
                        "name": "business",
                        "icon": "BankOutlined",
                        "systemId": 16,
                        "id": 16,
                        "routes": [
                          {
                            "path": "/admin**/business/settings/system",
                            "name": "system",
                            "icon": "SettingOutlined",
                            "iconCls": "&#xe734;",
                            "id": 345,
                            "routes": [
                              {
                                "name": "warning",
                                "path": "/admin**/business/settings/system/warning",
                                "locale": "menu.business.settings.system.warning",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Settings__System__Warning' */'D:/WEB_XGJ_PRO/xinguanjia_web_antdpro/src/pages/Business/Settings/System/Warning'), loading: LoadingComponent}),
                                "bussinessNo": 1101050,
                                "exact": true
                              },
                              {
                                "name": "operation-log",
                                "path": "/admin**/business/settings/system/operation-log",
                                "locale": "menu.business.settings.system.operation-log",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Settings__System__OperationLog' */'D:/WEB_XGJ_PRO/xinguanjia_web_antdpro/src/pages/Business/Settings/System/OperationLog'), loading: LoadingComponent}),
                                "bussinessNo": 1101051,
                                "exact": true
                              },
                              {
                                "name": "api-logs",
                                "path": "/admin**/business/settings/system/api-logs",
                                "locale": "menu.business.settings.system.api-logs",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Settings__System__ApiLogs' */'D:/WEB_XGJ_PRO/xinguanjia_web_antdpro/src/pages/Business/Settings/System/ApiLogs'), loading: LoadingComponent}),
                                "bussinessNo": 1101052,
                                "exact": true
                              },
                              {
                                "name": "network-speed",
                                "path": "/admin**/business/settings/system/network-speed",
                                "locale": "menu.business.settings.system.network-speed",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Settings__System__NetworkSpeed' */'D:/WEB_XGJ_PRO/xinguanjia_web_antdpro/src/pages/Business/Settings/System/NetworkSpeed'), loading: LoadingComponent}),
                                "bussinessNo": "1101053",
                                "exact": true
                              }
                            ]
                          },
                          {
                            "path": "/admin**/business/ecg",
                            "name": "ecg",
                            "icon": "FundOutlined",
                            "iconCls": "&#xe61f;",
                            "id": 345,
                            "routes": [
                              {
                                "name": "users",
                                "locale": "menu.business.ecg.users",
                                "path": "/admin**/business/ecg/users",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Ecg__Users' */'D:/WEB_XGJ_PRO/xinguanjia_web_antdpro/src/pages/Business/Ecg/Users'), loading: LoadingComponent}),
                                "bussinessNo": 2000011,
                                "exact": true
                              },
                              {
                                "name": "ecg-reports",
                                "path": "/admin**/business/ecg/ecg-reports",
                                "locale": "menu.business.ecg.ecg-reports",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Ecg__EcgReports' */'D:/WEB_XGJ_PRO/xinguanjia_web_antdpro/src/pages/Business/Ecg/EcgReports'), loading: LoadingComponent}),
                                "bussinessNo": 2000025,
                                "exact": true
                              },
                              {
                                "name": "ecg-records",
                                "path": "/admin**/business/ecg/ecg-records",
                                "locale": "menu.business.ecg.ecg-records",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Ecg__EcgRecords' */'D:/WEB_XGJ_PRO/xinguanjia_web_antdpro/src/pages/Business/Ecg/EcgRecords'), loading: LoadingComponent}),
                                "bussinessNo": 2000013,
                                "exact": true
                              },
                              {
                                "name": "bindings",
                                "path": "/admin**/business/ecg/bindings",
                                "locale": "menu.business.ecg.bindings",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Ecg__Bindings' */'D:/WEB_XGJ_PRO/xinguanjia_web_antdpro/src/pages/Business/Ecg/Bindings'), loading: LoadingComponent}),
                                "bussinessNo": 2000024,
                                "exact": true
                              },
                              {
                                "name": "log-list",
                                "path": "/admin**/business/ecg/log-list",
                                "locale": "menu.business.ecg.log-list",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Ecg__LogList' */'D:/WEB_XGJ_PRO/xinguanjia_web_antdpro/src/pages/Business/Ecg/LogList'), loading: LoadingComponent}),
                                "bussinessNo": 2000021,
                                "exact": true
                              },
                              {
                                "name": "warnings",
                                "path": "/admin**/business/ecg/warnings",
                                "locale": "menu.business.ecg.warnings",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Ecg__Warnings' */'D:/WEB_XGJ_PRO/xinguanjia_web_antdpro/src/pages/Business/Ecg/Warnings'), loading: LoadingComponent}),
                                "bussinessNo": 2000015,
                                "exact": true
                              },
                              {
                                "name": "weak",
                                "path": "/admin**/business/ecg/weak",
                                "locale": "menu.business.ecg.weak",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Ecg__Weak' */'D:/WEB_XGJ_PRO/xinguanjia_web_antdpro/src/pages/Business/Ecg/Weak'), loading: LoadingComponent}),
                                "bussinessNo": 2000039,
                                "exact": true
                              },
                              {
                                "name": "screen",
                                "path": "/admin**/business/ecg/screen",
                                "locale": "menu.business.ecg.screen",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Ecg__Screen' */'D:/WEB_XGJ_PRO/xinguanjia_web_antdpro/src/pages/Business/Ecg/Screen'), loading: LoadingComponent}),
                                "bussinessNo": 2000041,
                                "exact": true
                              },
                              {
                                "name": "screen-user",
                                "path": "/admin**/business/ecg/screen-user",
                                "locale": "menu.business.ecg.screen-user",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Ecg__ScreenUserConfirm' */'D:/WEB_XGJ_PRO/xinguanjia_web_antdpro/src/pages/Business/Ecg/ScreenUserConfirm'), loading: LoadingComponent}),
                                "bussinessNo": 2000046,
                                "exact": true
                              },
                              {
                                "name": "sampling",
                                "path": "/admin**/business/ecg/sampling",
                                "locale": "menu.business.ecg.sampling",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Ecg__Sampling' */'D:/WEB_XGJ_PRO/xinguanjia_web_antdpro/src/pages/Business/Ecg/Sampling'), loading: LoadingComponent}),
                                "bussinessNo": 2000042,
                                "exact": true
                              },
                              {
                                "name": "device",
                                "path": "/admin**/business/ecg/device",
                                "locale": "menu.business.ecg.device",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Ecg__Device' */'D:/WEB_XGJ_PRO/xinguanjia_web_antdpro/src/pages/Business/Ecg/Device'), loading: LoadingComponent}),
                                "bussinessNo": 2000044,
                                "exact": true
                              },
                              {
                                "name": "application",
                                "path": "/admin**/business/ecg/report-apply",
                                "locale": "menu.business.ecg.apply",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Ecg__ReportApplication' */'D:/WEB_XGJ_PRO/xinguanjia_web_antdpro/src/pages/Business/Ecg/ReportApplication'), loading: LoadingComponent}),
                                "bussinessNo": 2000047,
                                "exact": true
                              },
                              {
                                "name": "pacemaker",
                                "path": "/admin**/business/ecg/pacemaker",
                                "locale": "menu.business.ecg.pacemaker",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Ecg__Pacemaker' */'D:/WEB_XGJ_PRO/xinguanjia_web_antdpro/src/pages/Business/Ecg/Pacemaker'), loading: LoadingComponent}),
                                "bussinessNo": 2000048,
                                "exact": true
                              },
                              {
                                "name": "dynamic",
                                "path": "/admin**/business/ecg/dynamic",
                                "locale": "menu.business.ecg.dynamic",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Ecg__DynamicApply' */'D:/WEB_XGJ_PRO/xinguanjia_web_antdpro/src/pages/Business/Ecg/DynamicApply'), loading: LoadingComponent}),
                                "bussinessNo": 2000049,
                                "exact": true
                              },
                              {
                                "name": "remote",
                                "path": "/admin**/business/ecg/remote",
                                "locale": "menu.business.ecg.remote",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Ecg__RemoteApply' */'D:/WEB_XGJ_PRO/xinguanjia_web_antdpro/src/pages/Business/Ecg/RemoteApply'), loading: LoadingComponent}),
                                "bussinessNo": 2000050,
                                "exact": true
                              },
                              {
                                "name": "preview",
                                "path": "/admin**/business/ecg/preview",
                                "locale": "menu.business.ecg.preview",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Ecg__PreviewReport' */'D:/WEB_XGJ_PRO/xinguanjia_web_antdpro/src/pages/Business/Ecg/PreviewReport'), loading: LoadingComponent}),
                                "bussinessNo": 2000051,
                                "exact": true
                              },
                              {
                                "name": "deep-analysis",
                                "path": "/admin**/business/ecg/deep-analysis",
                                "locale": "menu.business.ecg.deep-analysis",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Ecg__DeepAnalysis' */'D:/WEB_XGJ_PRO/xinguanjia_web_antdpro/src/pages/Business/Ecg/DeepAnalysis'), loading: LoadingComponent}),
                                "bussinessNo": 2000053,
                                "exact": true
                              },
                              {
                                "name": "quick-check",
                                "path": "/admin**/business/ecg/quick-check",
                                "locale": "menu.business.ecg.quick-check",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Ecg__QuickCheck' */'D:/WEB_XGJ_PRO/xinguanjia_web_antdpro/src/pages/Business/Ecg/QuickCheck'), loading: LoadingComponent}),
                                "bussinessNo": 2000054,
                                "exact": true
                              },
                              {
                                "name": "follow-up",
                                "path": "/admin**/business/ecg/follow-up",
                                "locale": "menu.business.ecg.follow-up",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Ecg__ScreenFollowUp' */'D:/WEB_XGJ_PRO/xinguanjia_web_antdpro/src/pages/Business/Ecg/ScreenFollowUp'), loading: LoadingComponent}),
                                "bussinessNo": 2000055,
                                "exact": true
                              },
                              {
                                "name": "monitor-screen",
                                "path": "/admin**/business/ecg/monitor-screen",
                                "locale": "menu.business.ecg.monitor-screen",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Ecg__MonitorScreen' */'D:/WEB_XGJ_PRO/xinguanjia_web_antdpro/src/pages/Business/Ecg/MonitorScreen'), loading: LoadingComponent}),
                                "bussinessNo": 2000056,
                                "exact": true
                              }
                            ]
                          },
                          {
                            "path": "/admin**/business/online",
                            "name": "online",
                            "icon": "CommentOutlined",
                            "iconCls": "&#xe800;",
                            "id": 373,
                            "routes": [
                              {
                                "name": "analytic-orders",
                                "path": "/admin**/business/online/analytic-orders",
                                "locale": "menu.business.online.analytic-orders",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Online__AnalyticOrders' */'D:/WEB_XGJ_PRO/xinguanjia_web_antdpro/src/pages/Business/Online/AnalyticOrders'), loading: LoadingComponent}),
                                "bussinessNo": 2000032,
                                "exact": true
                              },
                              {
                                "name": "signature",
                                "path": "/admin**/business/online/signature",
                                "locale": "menu.business.online.signature",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Online__Signature' */'D:/WEB_XGJ_PRO/xinguanjia_web_antdpro/src/pages/Business/Online/Signature'), loading: LoadingComponent}),
                                "bussinessNo": 2000035,
                                "exact": true
                              },
                              {
                                "name": "interpretation-orders",
                                "path": "/admin**/business/online/interpretation-orders",
                                "locale": "menu.business.online.interpretation-orders",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Online__InterpretationOrders' */'D:/WEB_XGJ_PRO/xinguanjia_web_antdpro/src/pages/Business/Online/InterpretationOrders'), loading: LoadingComponent}),
                                "bussinessNo": 2000030,
                                "exact": true
                              },
                              {
                                "name": "intelligent-screen",
                                "path": "/admin**/business/online/intelligent-screen",
                                "locale": "menu.business.online.intelligent-screen",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Online__IntelligentScreen' */'D:/WEB_XGJ_PRO/xinguanjia_web_antdpro/src/pages/Business/Online/IntelligentScreen'), loading: LoadingComponent}),
                                "bussinessNo": 2000043,
                                "exact": true
                              }
                            ]
                          },
                          {
                            "path": "/admin**/business/statistics",
                            "name": "statistics",
                            "icon": "AreaChartOutlined",
                            "iconCls": "&#xe626;",
                            "id": 237,
                            "routes": [
                              {
                                "name": "user-area",
                                "path": "/admin**/business/statistics/user-area",
                                "locale": "menu.business.statistics.user-area",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Statistics__UserArea' */'D:/WEB_XGJ_PRO/xinguanjia_web_antdpro/src/pages/Business/Statistics/UserArea'), loading: LoadingComponent}),
                                "bussinessNo": 2100001,
                                "exact": true
                              },
                              {
                                "name": "user-usage",
                                "path": "/admin**/business/statistics/user-usage",
                                "locale": "menu.business.statistics.user-usage",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Statistics__UserUsage' */'D:/WEB_XGJ_PRO/xinguanjia_web_antdpro/src/pages/Business/Statistics/UserUsage'), loading: LoadingComponent}),
                                "bussinessNo": 2100004,
                                "exact": true
                              },
                              {
                                "name": "company-usage",
                                "path": "/admin**/business/statistics/company-usage",
                                "locale": "menu.business.statistics.company-usage",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Statistics__CompanyUsage' */'D:/WEB_XGJ_PRO/xinguanjia_web_antdpro/src/pages/Business/Statistics/CompanyUsage'), loading: LoadingComponent}),
                                "bussinessNo": 2100003,
                                "exact": true
                              },
                              {
                                "name": "doctor-report-amount",
                                "path": "/admin**/business/statistics/doctor-report-amount",
                                "locale": "menu.business.statistics.doctor-report-amount",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Statistics__DoctorReportAmount' */'D:/WEB_XGJ_PRO/xinguanjia_web_antdpro/src/pages/Business/Statistics/DoctorReportAmount'), loading: LoadingComponent}),
                                "bussinessNo": 2100005,
                                "exact": true
                              },
                              {
                                "name": "deep-analysis-amount",
                                "path": "/admin**/business/statistics/deep-analysis-amount",
                                "locale": "menu.business.statistics.deep-analysis-amount",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Statistics__DeepAnalysisAmount' */'D:/WEB_XGJ_PRO/xinguanjia_web_antdpro/src/pages/Business/Statistics/DeepAnalysisAmount'), loading: LoadingComponent}),
                                "bussinessNo": 2100006,
                                "exact": true
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "name": "login",
                    "path": "/login",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Login' */'D:/WEB_XGJ_PRO/xinguanjia_web_antdpro/src/pages/Login'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/welcome",
                    "name": "welcome",
                    "icon": "smile",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Welcome' */'D:/WEB_XGJ_PRO/xinguanjia_web_antdpro/src/pages/Welcome'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'D:/WEB_XGJ_PRO/xinguanjia_web_antdpro/src/pages/404'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              }
            ]
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'D:/WEB_XGJ_PRO/xinguanjia_web_antdpro/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      }
    ]
  },
  {
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'D:/WEB_XGJ_PRO/xinguanjia_web_antdpro/src/pages/404'), loading: LoadingComponent}),
    "exact": true
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
